.flatfinderTypo {
    font-family: 'btext';
    display: inline;
    color: #25283D;
    letter-spacing: 6px;
}

.flatFinderImageLogo {
        height: 25px;
        display: inline;
        margin-bottom: 5px;
        margin-right: 10px;
}

.flatfinderNav {
    color: #25283D !important;
}

.nav-call-to-action {
    padding-top: 0px;
    padding-bottom: 0px;
}

.navButton {
    font-weight: bold;
}