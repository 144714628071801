.FlatfinderFooter {
    padding-left: 80px;
    padding-bottom: 40px;
    padding-top: 47px;
    margin-top: 80px;
    background-color: #22252A;
    width: 100%;
}

a {
    text-decoration: none;
    color: white;
}

a:hover {
    opacity: 0.8;
    text-decoration: none;
    color: white;
}


.footerLinks {
    font-size: 26px;
    font-weight: lighter;
}

.footerLinks > div {
    margin-bottom: 24px;
}

.FlatfinderFooter > div > .headerLogo {
    margin-bottom: 45px;
    margin-left: 15px;
}

.copyright {
    margin-top: 16px;
    font-size: 14px;
    margin-left: 0px;
    color: white;
}

@media (max-width: 767px) {
    .FlatfinderFooter {
        padding-left: 24px;
        margin-top: 64px;
    }

    .copyright {
        font-size: 12px;
    }
}