.callToAction {
    background-color: #22252A;
    border-radius: 8px;
    color: white;
}

.callToActionAlign {
    display: flex;
    justify-content: center;
    align-items: center;
}

.callToActionImg {
    width: 100%;
    border-radius: 8px;
}

.callToActionBtn {
    font-size: 22px;
    font-weight: bold;
    margin-top: 50px;
}

.callToContainer {
    padding-left: 79px;
    padding-right: 79px;
}
.callToActionTitle {
    font-size: 43px;
    font-weight: bold;
}

.callToActionSubTitle {
    font-size: 25px;
}

@media (max-width: 767px) {
    .callToActionTitle {
        font-size: 32px;
        font-weight: bold;
        margin-top: 16px;
    }
    
    .callToActionSubTitle {
        font-size: 20px;
    }

    .callToActionBtn {
        font-size: 20px;
        font-weight: bold;
        margin-top: 50px;
    }

    .callToActionImg {
        border-radius: 0px;
        margin-left: -24px;
        width: calc(100% + 48px);
    }

    .callToAction {
        background-color: white;
        border-radius: 16px;
        color: #25283D;
        margin-bottom: 64px;
    }

    .callToContainer {
        padding-left: 24px;
        padding-right: 24px;
    }

    .callToActionAlign {
        flex-direction: column-reverse;
    }
}