.trashButton {
    position: absolute;
    right: -10px;
    top: -10px;
    background-color: white;
    color: black;
    z-index: 5;
    border: 1px solid black;
    border-radius: 25px;
}

.trashButton:hover {
    background-color: red;
    color: white;
    border: 1px solid red;
}

.SavedPropertiesmargin {
    margin-left: 20px;
    margin-right: 30px;
}