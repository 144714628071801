.imageSearchTrigger {
    max-width: 100%;
    border-radius: 6px;
}

.searchTriggerCard:hover {
    opacity: 0.6;
}
.searchTriggerTrait {
    color: white;
    background-color: #25283D;
    border-radius: 6px;
    padding-left: 8px;
    padding-right: 10px;
    font-size: 20px;
    padding-top: 5px;
    padding-bottom: 7px;
    margin-bottom: 8px;
    margin-right: 8px;
    width: 100%;
    display: inline-block;
    overflow-wrap: initial;
    max-width: 59%;
    min-width: 0%;
    width: auto;
}

.tagsContainer {
    position: absolute;
    width: 100%;
    bottom: 56px;
    max-width: calc(100% - 30px);
    left: 25px;
}

.searchProfileTriggerTitle {
    text-decoration: none;
    color: #25283D;
    font-size: 26px;
}

@media (max-width: 767px) {
    .searchTriggerTrait {
        font-size: 12px;
        display: inline-block;
        max-width: 40%;
        min-width: 0%;
        width: auto;
    }
    .tagsContainer {
        bottom: 35px;
        max-width: calc(100% - 30px);
    }
    .searchProfileTriggerTitle {
        font-size: 20px;
    }
}