.flatFinderInput {
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 2px solid black;
}

.filtersContainer {
    color: black;
    padding-right: 16px;
    padding-left: 16px;
    height: 100%;
}

.traitTitle {
    font-weight: 900;
    font-size: 20px;
    margin-left: 8px;
}

.selectedTrait {
    margin-top: 16px;
}

.traitButton {
    border-radius: 8px;
    border: none;
    padding-left: 8px;
    padding-right: 9px;
    margin-left: 8px;

}

.react-autosuggest__input {
    width: 100%;
    height: auto;
    padding: .5rem 1rem;
    font-size: .95rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    border: 1px solid #becad6;
    font-weight: 300;
    will-change: border-color,box-shadow;
    border-radius: .375rem;
    box-shadow: none;
    transition: box-shadow 250ms cubic-bezier(.27,.01,.38,1.06),border 250ms cubic-bezier(.27,.01,.38,1.06);
}

.react-autosuggest__suggestions-list {
    list-style-type: none;
    padding-left: 0;
}

.inactive {
    background-color: #F0F2F6;
    color: #9598AD;
}

.active {
    background-color: #25283D;
    color: white;
}

.removeTraitFlag {
    display: inline;
    font-size: 12px;
    color: #65687D;
    margin-left: 8px;
    cursor: pointer;
}

.viewMatchButton {
    width: 100%;
    background-color: #1477F8;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 20px;
    padding-top: 12px;
    margin-top: 16px;
    padding-bottom: 12px;
}

.viewMatchButton:disabled {
    opacity: 0.6;
}
.propertyContainer {
    margin-top: 40px;
}

.propertiesContainer {
    overflow: scroll;
    height: 100%;
}

.filterTitle {
    font-size: 20px;
    font-weight: bold;
}

.searchPage {
    overflow-x: hidden;
}

.matchNumberString {
    display: inline;
    font-size: 20px;
    font-weight: bold;
    color: #25283D;
    margin-right: 24px;
}

.searchString {
    display: inline;
    font-size: 16px;
    color: #25283D;
    font-weight: normal;
}

.searchStringContainer {
    margin-left: 16px;
    margin-top: 24px;
}

.inputMargin {
    margin-top: 24px;
}

.react-autosuggest__input {
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 2px solid black;
}
.react-autosuggest__input--focused {
    outline: none !important;
    border-bottom: 2px solid #1477F8;
}

.traitResult {
    color: #9598AD;
    background-color: #F0F2F6;
    padding-right: 7px;
    padding-left: 7px;
    padding-top: 2px;
    
    padding-bottom: 2px;
    display: inline;
    border-radius: 8px;
    margin-top: 106px;
    cursor: pointer;
}

.react-autosuggest__suggestion {
    margin-top: 18px;
    margin-right: 8px;
    display: inline;
}
.react-autosuggest__suggestions-list {
    margin-top: 18px;
}
.traitResult:hover {
    background-color: #25283D;
    color: white;
}

.searchFilterDetails {
    font-size: 12px;
    color: #9598AD;
    margin-top: -10px;
    margin-bottom: 10px;
}

.searchFilterDetails a {
    color: #25283D;
}

.searchFilterDetails a:hover {
    opacity: 0.7;
    color: #25283D;
}