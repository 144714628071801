.neighbourHoodPageHeadingImage {
    width: 100%;
}

.neighbourHoodPageTitle {
    font-size: 26px;
    color: #25283D;
    margin-top: 24px;
    margin-bottom: 24px;
}

.marginNeighbourhoodPage {
    margin-left: 24px;
    margin-right: 24px;
}

.neighbourHoodPageDesc {
    font-size: 16px;
    color: #25283D;
    white-space: pre-line;
    padding-bottom: 32px;
    border-bottom: 1px solid #DDDDDD;
}


.NeighbourHoodPageSearchTitle {
    font-size: 16px;
    color: #25283D;
    margin-top: 32px;
    font-weight: bold;
    margin-bottom: 24px;
}