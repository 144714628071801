.propertyImage {
    max-width: 100%;
    border-radius: 3px;
}

.title {
    font-size: 12px;
    color: #65687D;
}

.price {
    font-size: 24px;
    font-weight: bold;
    color: #25283D;
}

.address {
    font-size: 16px;
    font-weight: bold;
    color: #25283D;
}

.hasMatched {
    color: #009688;
}

.hasNotMatched {
    color: #FF6660;
}

.strikethrough {
    text-decoration:line-through;
}

.matchEntry {
    color: #25283D;
    font-size: 16px;
}

.matchContainer {
    width: 80%;
    margin-top: 16px;
}

.transportContainer {
    margin-top: 16px;
    font-size: 16px;
    color: #25283D;
}

.transportLogo {
    width: 14px;
}

.propertyInfos {
    padding-top: 6px;
}

.matchLabel {
    background-color: #532785;
    border-radius: 3px;
    font-size: 12px;
    font-weight: bold;
    padding-left: 6px;
    padding-right: 6px;
    color: white;
    max-width: 75px;
}

.wrapperProperty {
    margin-left: 16px;
    margin-right: 16px;
}
.propertyPreviewHover:hover {
    opacity: 0.7;
}
.slide {
    max-height: 350px !important;
}