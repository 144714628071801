.searchProfileContainer {
    background-color: white;
    padding: 24px;
    min-height: calc(100% - 72px);
}

.searchProfileTitle {
    font-size: 24px;
    font-weight: bold;
}

.likeHeart {
    color: #FF6660;
}

.likesSearchProfile {
    font-size: 14px;
    font-weight: lighter;
    margin-bottom: 14px;
}

.mustHave {
    background: #25283D;
    border-radius: 6px;
    color: white;
    padding: 8px;
    width: auto;
    font-size: 20px;
    display: inline;
}

.niceToHave {
    background: #65687D;
    border-radius: 6px;
    color: white;
    padding: 8px;
    font-size: 20px;
    width: auto;
    display: inline;
}

.searchProfileTitleSection {
    margin-bottom: 24px;
    font-size: 20px;
}

.marginBadges {
    margin-top: 0px;
    margin-bottom: 20px;
}

@media (max-width: 767px) {
    .mustHave {
        font-size: 12px;
    }

    .niceToHave {
        font-size: 12px;
    }
}