.likesPropertyThumb {
    font-size: 15px;
    color: #25283D;
    font-weight: lighter;
    margin-top: 16px;
    margin-bottom: 5px;
}

.propertyThumbImg {
    width: 100%;
    border-radius: 4px;
}

.titlePropertyThumb {
    font-size: 20px;
    font-weight: bold;
    color: #25283D;
}

.pricePropertyThumb {
    display: inline;
    color: #25283D;
    font-weight: bold;
}

.propertyThumbHover:hover {
    opacity: 0.7;
}