.stockImage1 {
    border-radius: 8px;
    width: 100%;
    margin-bottom: 16px;
}

.stockImage2 {
    border-radius: 8px;
    width: 100%;
}

.stockImage3 {
    border-radius: 8px;
    margin-left: 16px;
    width: 100%;
    height: 100%;
}

.noPadding {
    padding: 0px;
}

.landingMargins {
    margin-right: 100px;
    margin-left: 100px;
}

.headerLanding {
    color: white !important;
    background-color: #22252A;
    border-radius: 12px;
    height: 100%;
    padding-left: 80px;
    padding-right: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.flatfinderTypoLanding {
    font-family: 'btext';
    display: inline;
    color: white;
    letter-spacing: 6px;
}

.flatFinderImageLogoLanding {
    height: 25px;
    display: inline;
    margin-bottom: 5px;
    margin-right: 10px;
}

.landingHeaderTitle {
    font-size: 33px;
    font-weight: bold;
}

.landingHeaderSubTitle {
    font-size: 26px;
}
.howItWorks {
    background-color: #F3F4F5;
    padding-left: 40px;
    padding-bottom: 80px;
    padding-right: 92px;
    padding-top: 32px;
    border-radius: 12px;
    margin-top: 46px;
    margin-left: 0px !important;
    width: 100%;
    color: #25283D;
}

@media (max-width: 767px) {
    .landingMargins {
        margin-right: 24px !important;
        margin-left: 24px !important;
    }

    .headerLanding {
        color: #25283D !important;
        background-color: white;
        border-radius: 0;
        height: 100%;
        padding-left: 0px;
        padding-right: 0px;
        margin-left: 12px;
        margin-right: 12px;
    }

    .landingHeaderTitle {
        font-size: 26px;
    }

    .landingHeaderSubTitle {
        font-size: 18px;
    }
    
    .stockImagesContainer {
        max-width: calc(100% - 12px);
        margin-left: 12px;
    }

    .fakeProperty {
        display: none;
    }

    .verticalPadFixLanding {
        margin-bottom: 92px;
    }
    
    .profileTriggerContainer {
        overflow-x: auto;
      }
      .profileTriggerContainer > .row {
        width: 270%; /* = 100% * 4/3 */
      }

      .marginFix {
        margin-left: -15px;
        margin-right: 15px;
        display: flex;
        flex-direction: column-reverse;
    }

    .howItWorks {
        background-color: #F3F4F5;
        padding: 0px;
        padding-left: 12px;
        padding-right: 12px;
        padding-bottom: 32px;
        margin-top: 46px;
        margin-left: -24px !important;
        width: calc(100% + 48px);
        color: #25283D;
    }
}

@media (max-width: 992px) {
    .newestListings {
        overflow-x: auto;
      }
      .newestListings > .row {
        width: 250%; /* = 100% * 4/3 */
      }
}
.profileTriggerContainer {
    overflow-x: auto;
  }

.LandingsectionTitle {
    font-size: 33px;
    color: #25283D;
    margin-top: 43px;
    margin-bottom: 24px;
}

.landingSubtext {
    font-size: 24px;
    margin-top: 15px;
}

.fakePropertyImage {
    width: 100%;
}

.green {
    color: #009688;
    font-size: 30px;
}

.fakeTitle {
    font-size: 20px;
    font-weight: bold;
}

.boldedPrice {
    font-weight: bold;
    color: #25283D;
}

.fakePrice {
    font-weight: lighter;
    color: #65687D;
}

.traitListed {
    margin-bottom: 30px;
}

.fakeProperty {
    background-color: white;
    padding: 24px;
}

.neighbourhoodCont {
    margin-bottom: 15px;
}

.marginFix {
    margin-left: -15px;
    margin-right: 15px;
}

.newestListings {
    margin-bottom: 43px;
}

.BigtraitTitle {
    font-size: 16px;
    font-weight: bold;
}

.traitSubtitle {
    font-size: 16px;
}
