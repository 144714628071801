.neighbourhoodImage {
    max-width: 100%;
    border-radius: 6px;
}

.neighbourhoodTitle {
    color: #25283D;
    font-weight: bold;
}

.neighbourhoodSubTitle {
    color: #25283D;
    font-weight: lighter;
}

.neighbourhoodContainer:hover {
    opacity: 0.6;
}